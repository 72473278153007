import axios from 'axios';
import notification from './notifications';
import { findDeepProp } from '../plugins/helpers';

const axiosPlugin = {};

// Program all Axios defaults here
axios.defaults.baseURL = process.env.VUE_APP_API_ROOT || '';

axiosPlugin.request = (config) => {
  axiosPlugin.store.commit('setDoingAjax', true);

  // If the store is holding a "Token" we will sent the token to the API as part of a
  // Authorization HEADER.

  if (axiosPlugin.store.getters[ 'login/isLoggedIn' ]
    && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${axiosPlugin.store.getters[ 'login/isLoggedIn' ]}`;
  }

  return config;
};

axiosPlugin.requestError = (error) => {
  axiosPlugin.store.commit('setDoingAjax', false);
  return Promise.reject(error);
};

axiosPlugin.response = (response) => {
  axiosPlugin.store.commit('setDoingAjax', false);
  return response;
};

axiosPlugin.responseError = (error) => {
  /**
   * Display some global messages here
   */
  axiosPlugin.store.commit('setDoingAjax', false);

  if (error.response) {
    // Authorization error, log the user out!
    if ([ 403, 401 ].indexOf(error.response.status) !== -1) {
      // User not authorized, log it out.
      const endpoint = String(error.response.config.url).split('/').pop();
      let allValues = String(error.response.config.url).split('/');
      // console.log('in responseError, error.response:', error.response, 'allValues:', allValues, 'endpoint:', endpoint);
      const byPass = [ 'logout', 'authenticate', 'EmailConfirmation', 'TokenValidation', 'ResetPassword', 'SelfValidation', 'GetSingleUserData' ];
      if (!byPass.includes(endpoint) && !allValues.includes('ach-requests')) {
        axiosPlugin.store.dispatch('login/logOut');
        return;
      }
    }
  }

  // We got to the end of this, something really odd happened and no notification was displayed to the user.
  error.unnotified = true;
  return Promise.reject(error);
};


const defaultResponseController = (error) => {
  console.log(error);
  // If a list of errors is found, we join them and then display the result.
  const dataErrors = findDeepProp('response.data.errors', error);
  if (dataErrors !== null) {
    let errors = '';
    for (let field in dataErrors) {
      errors += `${dataErrors[field].join("\n")},`;
    }
    notification.warning(errors.slice(0, -1), { duration: 10000 });
    return;
  }

  // if a message is found, we display it.
  const dataMessage = findDeepProp('response.data.message', error);
  if (typeof dataMessage === 'string') {
    notification.warning(dataMessage);
    return;
  }

  // Ignore (for now) status code 404
  const dataStatus = findDeepProp('response.status', error);
  if (dataStatus === 404) {
    return;
  }

  // Server error for status codes 500+
  if (dataStatus >= 500) {
    notification.error(`Our server is not responding (${error.response.status}), please try again later.`);
    return;
  }

  console.error(error);

  return;
};

/**
 * SETTING UP SOME INTERCEPTORS FOR NUXT
 */
// Add a request interceptor
axios.interceptors.request.use(axiosPlugin.request, axiosPlugin.requestError);
// Add a response interceptor
axios.interceptors.response.use(axiosPlugin.response, axiosPlugin.responseError);


export default {
  install: (Vue, { store }) => {
    Vue.prototype.$axios = axios;
    Vue.prototype.$defaultResponseController = defaultResponseController;

    Vue.axios = axios;

    store.$axios = axios;
    store.$defaultResponseController = defaultResponseController;

    axiosPlugin.store = store;
    axiosPlugin.Vue = Vue;
  },
};
