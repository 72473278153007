import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    // clientId: '83792de3-dc0c-45e5-a829-0d2e5a0aec92',
    clientId: process.env.VUE_APP_MSAL_CLIENTID,
    authority: 'https://login.microsoftonline.com/2046864f-68ea-497d-af34-a6629a6cd700',
    redirectUri: process.env.VUE_APP_ROOT,
    validateAuthority: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default {
  namespaced: true,
  state: {
    loginResponse: null,
    loginRequest: {
      // scopes: [ "api://31257e1d-881f-4b64-83e5-291e3ae9aca0/vendor_payment.read" ],
      scopes: [ process.env.VUE_APP_MSAL_SCOPES ],
    },
  },
  mutations: {
    loginResponse (state, payload) {
      state.loginResponse = payload;
    },
  },
  actions: {
    async startSession ({ dispatch, state }, params) {

      // console.log('msal.js, dispatch:', dispatch, 'state:', state, 'params:', params);
      if (state.loginResponse) {
        await dispatch('silentLogin', params);
      }

      if (!state.loginResponse) {
        await dispatch('popupLogin');
      }

      await dispatch('login', params);

    },

    async silentLogin ({ state, commit }) {

      const tokenRequest = {
        ...state.loginRequest,
        account: state.loginResponse.account,
      };

      try {
        const silentLogin = await this.msalInstance.acquireTokenSilent(tokenRequest);
        commit('loginResponse', silentLogin);
      } catch (err) {
        this.$error(err.response.data.message);
      }

    },

    async popupLogin ({ commit, state }, params) {
      // console.log('msal.js popupLogin is running');
      try {
        const popupLogin = await msalInstance.loginPopup(state.loginRequest);
        commit('loginResponse', popupLogin);
      } catch (err) {
        this.$error(err.response.data.message);
      }
    },

    async login ({ dispatch, state }, params) {

      // console.log('msal.js login, dispatch:', dispatch, 'state:', state, 'params:', params);
      try {
        const msalAuth = await this.$axios({
          url: `/admin/authenticate`,
          method: 'post',
          data: {},
          headers: {
            'Authorization': `Bearer ${state.loginResponse.accessToken}`,
          },
        });
        msalAuth.data.routeAfterLogin = params.routeAfterLogin;
        dispatch('login/login', msalAuth.data, { root: true });
      } catch (err) {
        this.$error(err.response.data.message);
      }

    },
  },
};
