import cityseal from '@/config/cityseal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const findProp = (prop, obj) => {
  if (typeof obj !== 'object') {
    return null;
  }

  if (typeof prop !== 'string') {
    throw Error('Property has to be a string! ');
  }

  let propValue = null;

  if (obj[prop]) {
    return obj[prop];
  }

  for (let key in obj) {
    propValue = findProp(prop, obj[key]);
    if (propValue !== null) {
      break;
    }
  }

  return propValue;
};

const findDeepProp = (props, obj) => {
  if (typeof props !== 'string' && !(props instanceof Array)) {
    throw Error('Property has to be a string or array!');
  }

  if (typeof props === 'string') {
    let split = props.split('.');
    return findDeepProp(split, obj);
  }

  const element = props.shift();

  let propValue = findProp(element, obj);
  if (propValue !== null && props.length > 0) {
    propValue = findDeepProp(props, propValue);
  }

  return propValue;
};

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign
      + (j ? i.substr(0, j) + thousands : '')
      + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1"
      + thousands)
      + (decimalCount ? decimal
      + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    return amount || '';
  }
}

function formatPhone(number, useExtension = true) {
  // console.log('helpers.js formatPhone is running, number:', number, 'useExtension:', useExtension);
  let phone = number.trim().split("").filter(c => {
    let regex = new RegExp(/\d/);
    return regex.test(c);
  });

  number = '';
  for (let i=0; i<phone.length;i++) {
    if (i === 0) {
      number += '(';
    }
    if (i === 3) {
      number += ') ';
    }
    if (i === 6) {
      number += ' - ';
    }
    if (useExtension && i === 10) {
      number += ' x: ';
    }
    number += phone[i] || '';
  }
  if (useExtension) {
    number = number.substring(0, 27);
  } else {
    number = number.substring(0, 16);
  }
  return number;
}

const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));


const downloadCSV = (data, fileName, headers) => { // eslint-disable-line
  var lineArray = [];
  data.forEach(function (info, index) { // eslint-disable-line
    let infoArray = [];
    if (info instanceof Object) {
      infoArray = Object.values(info);
      // Fix Comma Issues

      infoArray = infoArray.map( val => `"${val}"` );
    }
    var line = infoArray.join(",");
    lineArray.push(line);
  });
  var csvContent = lineArray.join("\n");
  let universalBOM = "\uFEFF"; // eslint-disable-line

  var blob = new Blob([ csvContent ], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, `${fileName}.csv`);
  } else {
    // var encodedUri = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvContent);
    var encodedUri = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    link.setAttribute("target", '_blank');
    link.style = "visibility: hidden";
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }
};

const downloadPDF = (data, fileName, title = '', columnStyles = {}, headStyles = {}) => {
  const head = data.shift();

  var doc = new jsPDF();

  doc.setFontSize(14);
  doc.addImage(cityseal, 'JPEG', 4, 3, 18, 18);

  let xOffset = (doc.internal.pageSize.width / 2);
  doc.text(title, xOffset, 14, { align: 'center' });

  doc.autoTable({
    startY: 24,
    margin: 4,
    styles: {
      fontSize: 9,
      valign: 'middle',
    },
    columnStyles,
    headStyles: {
      ...{
        fillColor: [ 15, 77, 144 ],
      },
      ...headStyles,
    },
    head: [ head ],
    body: data,
  });
  doc.save(`${fileName}.pdf`);
};

export {
  findDeepProp,
};

export default {
  install(vue, { store }) {
    vue.prototype.$helpers = {
      formatMoney,
      formatPhone,
      arrayToObject,
      downloadCSV,
      downloadPDF,
      findDeepProp,
    };
    store.$helpers = vue.prototype.$helpers;
  },
};
