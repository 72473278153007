<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column login-box card is-two-fifths content">
        <div class="p-md">
          <h2 class="has-text-centered">
            Login
          </h2>
          <p class="has-text-left">
            If you are a City vendor wishing to get detailed information about the status
            of payments, or
            <a
              target="_blank"
              @click.prevent="showEnrollModal"
            >enroll in electronic payments</a>
            , use the form below to log in. If you are
            not registered, click on the 'Register Here' button to create an account.
            <a
              target="_blank"
              :href="$router.resolve({ name: 'ach-vendor-letter' }).href"
            >More information about electronic payments.</a>.
          </p>

          <ValidationObserver
            v-slot="validation"
            tag="div"
          >
            <input-form :errors="validation.errors">
              <div class="field">
                <vee-textbox
                  v-model="email"
                  name="E-mail"
                  rules="required|email"
                  label="E-mail"
                  placeholder="E-mail"
                  required
                />
              </div>
              <div class="field">
                <vee-textbox
                  v-model="password"
                  name="Password"
                  placeholder="Password"
                  rules="required"
                  type="password"
                  required
                />
              </div>
              <div class="field">
                <div class="control vertical-align-middle">
                  <router-link :to="{ name: 'forgot-password' }">
                    Forgot Password?
                  </router-link> | <router-link :to="{ name: 'register' }">
                    Register Here
                  </router-link>
                </div>
              </div>
              <div class="field">
                <div class="control has-text-centered">
                  <button
                    class="button is-primary"
                    :class="{ 'is-loading': loggingIn }"
                    @click.prevent="logIn(validation)"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import NewAppNotification from "@/components/NewAppNotification";
import { Textbox } from '@phila/phila-ui';
import InputForm from '@/components/InputForm';

import {
  extend,
  withValidation,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "LoginPage",
  components: {
    VeeTextbox,
  },
  data() {
    return {
      email: '',
      password: '',
      loggingIn: false,
    };
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
      message: (_, values) => 'the E-mail field must be a valid email',
    });
  },
  mounted() {
    if (this.$route.query.redirected === "yes") {
      this.$modal.hide();
      this.$modal.show(NewAppNotification);
    }

    if (this.$sCache.get('idle-logout')) {
      this.$modal.show('dialog', {
        title: 'Idle',
        text: `Your session has expired due to an extended period of inactivity`,
        buttons: [
          {
            title: 'Ok',
            default: true,
            handler: () => {
              this.$sCache.set('idle-logout', false);
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    }
  },
  methods: {
    showEnrollModal() {
      console.log('showEnrollModal is running');
      this.$modal.show('dialog', {
        title: 'Electronic Payments (ACH)',
        text: `The City of Philadelphia encourages all vendors to enroll in electronic payments.
          </br>Electronic payment provides vendors immediate access to funds through a more secure method of payment than a paper check.`,
        buttons: [
          {
            title: 'Ok',
            default: true,
            close: true,
          },
        ],
      });
    },
    async logIn(validation) {
      console.log('logIn running, validation:', validation);
      // const valid = await this.$refs['login-form'].validate();
      const valid = await validation.validate();
      if (!valid) {
        return false;
      }

      this.loggingIn = true;
      const result = await this.$store.dispatch('login/startSession', {
        email: this.email,
        password: this.password,
      });

      this.loggingIn = false;
      if (result !== false) {
        result.data.email = this.email;
        this.$store.dispatch('login/login', result.data);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
 .vertical-align-middle {
   * {
     vertical-align: middle;
   }
 }
</style>
