import Vue from 'vue';
import Vuex from 'vuex';

// modules
import users from '@/store/users';
import login from '@/store/login';
import vendors from '@/store/vendors';
import departments from '@/store/departments';
import units from '@/store/units';
import invoices from '@/store/invoices';
import ach from '@/store/ach';
import msal from '@/store/msal';
// import { EventBus } from '@/plugins/bus.js';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    users,
    login,
    vendors,
    departments,
    units,
    invoices,
    ach,
    msal,
  },
  state: {
    doingAjax: false,

    // This state hides and shows the fullscreen loading animation
    fullScreenLoading: false,
    isLoading: true,
    maintenanceResponse: null,
  },
  getters: {},
  mutations: {
    setFullScreenLoading(state, status) {
      state.fullScreenLoading = status;
    },
    setDoingAjax(state, b) {
      state.doingAjax = b;
    },

    setMaintenanceResponse(state, response) {
      state.maintenanceResponse = response;
    },
  },
  actions: {
    async healthCheck({ commit }) {
      const isMaintenance = document.location.href.indexOf('maintenance') !== -1;

      // sometimes the system is not on maintenance, it is just offline connection
      if (navigator.onLine === false) {
        if (isMaintenance) {
          window.location.href = '/';
          return;
        }
        return true;
      }

      try {
        const response = await this.$axios.get('health-check');
        // console.log("Health-Check-Reponse", response);

        /**
         * Last Maintenance Response:
         * TODO: Remove this
         */
        this.$sCache.set('maintenance', response);
        commit('setMaintenanceResponse', response);

        if (response.data.message !== 'ok') {
          if (!isMaintenance) {
            window.location.href = '/maintenance';
            return;
          }
        } else {
          if (isMaintenance) {
            window.location.href = '/';
            return;
          }
        }
      } catch (err) {
        console.log("Health-Check-Reponse", err);

        /**
         * Last Maintenance Response:
         * TODO: Remove this
         */
        this.$sCache.set('maintenance', err);
        commit('setMaintenanceResponse', err);

        if (!isMaintenance) {
          window.location.href = '/maintenance';
          return;
        }
      }
    },
  },
});
