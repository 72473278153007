import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';
import constants from '@/config/constants';

const baseUserObject = () => {
  return {
    firstName: "",
    lastName: "",
    emailAddress: "",
    title: "",
    phoneNumber: "",
    phoneExtension: "",
    password: "",
  };
};

const userObject = () => {
  return { taxIDNumber: "", ...baseUserObject() };
};

// const adminObject = () => {
//   return {
//     userName: '',
//     registeredDate: '',
//     lastLoginDate: '',
//     modifiedBy: '',
//     modifiedDate: '',
//   };
// };

const userAdminObject = () => {
  return {
    ...baseUserObject(),
    employeeNumber: "",
    departmentNumber: "",
    registeredDate: "",
    lastLoginDate: "",
    modifiedBy: "",
  };
};

const state = () => ({
  updatingProfile: false,
  updatingPassword: false,
  userObject: userObject(),
  userAdminObject: userAdminObject(),
  adminObject: userObject(),
  userAdmins: [],
  admins: [],
  users: [],
  curUserData: {},
});

const getters = {
  getField,
  getUserData: (state) => (type) => {
    return JSON.parse(JSON.stringify(state[type]));
  },
};

const mutations = {
  updateField,

  setUserObject(state, user) {
    Object.assign(state.userObject, user);
  },

  setUserAdminObject(state, user) {
    Object.assign(state.userAdminObject, user);
  },

  setAdminObject(state, admin) {
    Object.assign(state.adminObject, admin);
  },

  cleanUserObject(state) {
    state.userObject = userObject();
  },

  cleanUserAdminObject(state) {
    state.userAdminObject = userAdminObject();
  },

  cleanAdminObject(state) {
    state.adminObject = userObject();
  },

  setIsUpdatingProfile(state, value) {
    state.updatingProfile = value;
  },

  setIsUpdatingPassword(state, value) {
    state.updatingPassword = value;
  },

  setUserAdmins(state, userAdmins) {
    state.userAdmins = userAdmins;
  },

  setAdmins(state, admins) {
    state.admins = admins;
  },

  setUsers(state, users) {
    // state.users = users;
    state.userAdmins = users.filter(user => user.role === constants.DEPTADMIN);
    state.users = users.filter(user => user.role === constants.USER);
    state.admins = users.filter(user => user.role === constants.ADMINISTRATOR);
  },

  addUser(state, user) {
    // state.users.push(user);
    if (user.role === constants.ADMINISTRATOR) {
      state.admins.push(user);
      return;
    }
    if (user.role === constants.DEPTADMIN) {
      state.userAdmins.push(user);
      return;
    }
    if (user.role === constants.USER) {
      state.users.push(user);
      return;
    }
  },

  updateUser(state, user) {
    console.log('store/users.js updateUser is running, user:', user);
    if (user.role === constants.ADMINISTRATOR) {
      let index = state.admins.findIndex(u => u.id === user.id);
      Vue.set(state.admins, index, { ...state.admins[index], ...user });
      return;
    }
    if (user.role === constants.DEPTADMIN) {
      let index = state.userAdmins.findIndex(u => u.id === user.id);
      Vue.set(state.userAdmins, index, { ...state.userAdmins[index], ...user });
      return;
    }
    if (user.role === constants.USER) {
      let index = state.users.findIndex(u => u.id === user.id);
      Vue.set(state.users, index, { ...state.users[index], ...user });
      return;
    }
  },

  removeUser(state, user) {
    if (user.role === constants.ADMINISTRATOR) {
      const index = state.admins.findIndex(u => u.id === user.id);
      state.admins.splice(index, 1);
      return;
    }
    if (user.role === constants.DEPTADMIN) {
      const index = state.userAdmins.findIndex(u => u.id === user.id);
      state.userAdmins.splice(index, 1);
      return;
    }
    if (user.role === constants.USER) {
      const index = state.users.findIndex(u => u.id === user.id);
      state.users.splice(index, 1);
      return;
    }
  },

  setCurUserData(state,  user) {
    state.curUserData = user;
  },
};

const actions = {
  async getCurUserData({ commit }) {
    try {
      console.log('trying GetSingleUserData');
      const { data } = await this.$axios.get('/Users/GetSingleUserData', {});
      commit('setCurUserData', data);
      return true;
    } catch (err) {
      // TODO: Catch the error here
      return false;
    }
  },

  async changePassword({ commit }, payload) {
    try {
      commit('setIsUpdatingPassword', true);
      await this.$axios.put('/Users/ChangePassword', payload);

      this.$sCache.set('flash-message',
        { type: 'success', message: `Your password has been saved` }
      );
      commit('setIsUpdatingPassword', false);
      document.location = '/profile';
    } catch (err) {
      // TODO: Catch the error here
      commit('setIsUpdatingPassword', false);
      this.$defaultResponseController(err);
      return false;
    }
  },

  async register({ commit }, userData) {
    console.log('users.js register function is running, userData:', userData);
    commit('setIsUpdatingProfile', true);
    try {
      // By and extrange thing I have to sent the user role here.
      userData.role = constants.USER;
      const { data } = await this.$axios.post('/Users/CreatePendingUser', userData);
      commit('setIsUpdatingProfile', false);
      return data;
    } catch (err) {
      commit('setIsUpdatingProfile', false);
      this.$defaultResponseController(err);
      return false;
    }
  },

  async selfValidation(ctx, userData) {
    try {
      const { data } = await this.$axios.post('/Users/SelfValidation', userData);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },

  async update({ commit }, user) {
    console.log('store/users.js async update is running, user:', user);
    let endpoint = '/Users/Update';
    if (user.page !== 'profile') {
      endpoint = `${endpoint}/${user.id}`;
    }
    return new Promise((resolve, reject) => { // eslint-disable-line
      commit('setIsUpdatingProfile', true);
      this.$axios.patch(endpoint, user)
        .then(response => {
          if (typeof response.data === 'object') {
            response.data = this.$readableDates(response.data, true);
            commit('updateUser', response.data);
          } else {
            this.$warning(`Oops, something failed updating the user, please try again,
if the problem persists call the system administrator`);
          }
          commit('setIsUpdatingProfile', false);
          resolve(response.data);
        })
        .catch(err => {
          this.$defaultResponseController(err);
          commit('setIsUpdatingProfile', false);
          resolve(false);
        });
    });
  },

  async getUsers({ commit, rootState }) {
    console.log('users.js getUsers is running');
    // TODO: Uncomment this when get users returns department admin users
    try {
      let endpoint = '/Users';
      if (!this.$isAnyAdmin(rootState.login.loggedIn.type)) {
        endpoint = '/Users/GetCoworkers';
      }
      const response = await this.$axios.get(endpoint, {});
      if (typeof response.data === 'object') {
        if (response.data.length > 0) {
          response.data = this.$readableDates(response.data, true);
          commit('setUsers', response.data);
        } else {
          commit('setUsers', []);
        }
      }
    } catch (err) {
      this.$defaultResponseController(err);
      commit('setUsers', []);
    }
  },

  async add({ commit }, payload) {
    try {
      commit('setIsUpdatingProfile', true);
      let { data } = await this.$axios.post('/Users/CreateValidUser', payload);
      data = this.$readableDates(data, true);
      commit('addUser', data);
      commit('setIsUpdatingProfile', false);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      commit('setIsUpdatingProfile', false);
      return false;
    }
  },

  async delete ({ commit }, user) {
    try {
      commit('setIsUpdatingProfile', true);
      const { data } = await this.$axios.put(`/Users/DeleteUser`, {}, { params: { userId: user.id }});
      commit('removeUser', user);
      commit('setIsUpdatingProfile', false);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      commit('setIsUpdatingProfile', false);
      return false;
    }
  },

  updateUserStatus({ commit }, payload) {
    let endpoint = '/Users/SetStatus';
    if (!this.$isAnyAdmin(payload.curUserType)) {
      endpoint = '/Users/SetCoworkerStatus';
    }

    return new Promise(async (resolve, reject) => { // eslint-disable-line
      try {
        const response = await this.$axios.put(endpoint, payload);
        if (typeof response.data === 'object') {
          response.data = this.$readableDates(response.data, true);
          commit('updateUser', response.data);
        }
        resolve(true);
      } catch (err) {
        this.$defaultResponseController(err);
        resolve(false);
      }
    });
  },
};

export default {
  namespaced: true,
  state: state(),
  getters,
  mutations,
  actions,
};
