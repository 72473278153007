const state = () => ({
  submittedAchData: null,
  allAchData: null,
  achData: null,
  voidedCheck: null,
  openFilterValues: [ 'Transferred — pending new department action', 'Pending department approval' ],
  transferredFilterValues: [ 'Verified and waiting for FAMIS input', 'Transferred to ACH admin', 'Rejected — pending ACH admin review' ],
  closedFilterValues: [],
});

const mutations = {
  setSubmittedAchData(state, data) {
    state.submittedAchData = data;
  },
  setAllAch(state, data) {
    state.allAchData = data;
  },
  setAch(state, data) {
    state.achData = data;
  },
  setAchBank(state, data) {
    state.achBank = data;
  },
  setVoidedCheck(state, data) {
    state.voidedCheck = data;
  },
  setOpenFilterValues(state, data) {
    state.openFilterValues = data;
  },
  setTransferredFilterValues(state, data) {
    state.transferredFilterValues = data;
  },
  setClosedFilterValues(state, data) {
    state.closedFilterValues = data;
  },
};

// public const string WaitingAuthorization = "WaitingAuthorization";
// public const string Rejected = "RejectedWaitingFinance";
// public const string AuthorizedWaitingFamis = "AuthorizedWaitingFamis";
// public const string EnteredInFamis = "EnteredInFamis";
// public const string Forwarded = "Forwarded";
// public const string RejectedCompleted = "RejectedCompleted";

const actions = {
  async getAllAch({ commit }, departmentId) {
    console.log('ach.js getAllAch is running, departmentId:', departmentId);
    let endpoint = '/ach-requests?';
    if (departmentId) {
      endpoint += `DepartmentId=${departmentId}`;
    }
    try {
      const response = await this.$axios.get(endpoint);
      if (response.data) {
        commit('setAllAch', response.data);
      } else {
        commit('setAllAch', []);
      }
      return response;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },
  async getAch({ commit }, uuid) {
    console.log('ach.js getAch is running, uuid:', uuid);
    let endpoint = '/ach-requests/';
    //TODO look into how to do this more efficiently
    if (uuid) {
      endpoint += `${uuid.humanReadableId}`;
    }
    try {
      const response = await this.$axios.get(endpoint);
      if (response.data) {
        commit('setAch', response.data);
        // this.$sCache.set(`ach-${uuid.humanReadableId}`, response.data, 'fiveminutes');
      } else {
        commit('setAch', []);
      }
      return response;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },

  async clearAchBank({ commit }) {
    commit('setAchBank', {});
  },

  async getAchBank({ commit }, uuid) {
    console.log('store/ach.js getAchBank is running, uuid:', uuid);
    let endpoint = '/ach-requests/BankData?';
    if (uuid) {
      endpoint += `achRequestId=${uuid.humanReadableId}`;
    }
    console.log('in getAchBank, commit:', commit, 'uuid:', uuid, 'endpoint:', endpoint);
    try {
      const response = await this.$axios.get(endpoint);

      if (response.data) {
        commit('setAchBank', response.data);
        // this.$sCache.set(`ach-${uuid.humanReadableId}`, response.data, 'fiveminutes');
      } else {
        commit('setAchBank', []);
      }
      return response;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },

  async checkAchBank({ commit }, uuid) {
    let endpoint = '/ach-requests/CheckBankData';
    try {
      const data = await this.$axios.put(endpoint, { "achRequestId": uuid.humanReadableId, "bankAccountNumberLastFourDigits": uuid.accountNumber });
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },

  async getVoidedCheck({ commit }, uuid) {
    console.log('store/ach.js getVoidedCheck is running, uuid:', uuid);
    let endpoint = `/ach-requests/${uuid.humanReadableId}/GetImage`;
    // console.log('in getVoidedCheck, commit:', commit, 'uuid:', uuid, 'endpoint:', endpoint);
    try {
      const response = await this.$axios.get(endpoint, { responseType: 'arraybuffer' });
      // console.log('response:', response, 'response.headers:', response.headers);
      return response;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },

  async submitAch({ commit }, formData) {
    try {
      const data = await this.$axios.post('/ach-requests',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      commit('setSubmittedAchData', data.data);
      return data;
    } catch (err) {
      let response = err.response.data;
      console.log('in submitAch, response:', response);
      this.$defaultResponseController(err);
      return response;
    }
  },

  async submitAchApproval({ commit }, formData) {
    try {
      const data = await this.$axios.put('/ach-requests/approveRequest', formData);
      console.log('store/ach.js submitAchApproval is running, data:', data);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },

  async submitAchDenial({ commit }, formData) {
    let endpoint = '/ach-requests/DenyRequest';
    try {
      const data = await this.$axios.put(endpoint, { "humanReadableId": formData.humanReadableId, "reason": formData.reason });
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },

  async submitAchCompleteDenial({ commit }, formData) {
    let endpoint = '/ach-requests/'+formData.achRequestId+'/RejectedCompleted';
    console.log('in submitAchCompleteDenial, formData:', formData, 'endpoint:', endpoint);
    try {
      const data = await this.$axios.patch(endpoint);
      console.log('store/ach.js submitAchCompleteDenial is running, data:', data);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },

  async submitAchCompleteDenialWithNote({ commit }, formData) {
    let endpoint = '/ach-requests/'+formData.achRequestId+'/RejectedCompletedWithNote';
    console.log('in submitAchCompleteDenialWithNote, formData:', formData, 'endpoint:', endpoint);
    try {
      const data = await this.$axios.patch(endpoint, { "achRequestId": formData.achRequestId, "notes": formData.note });
      console.log('store/ach.js submitAchCompleteDenialWithNote is running, data:', data);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },

  async submitAchConfirmFamis({ commit }, formData) {
    let endpoint = '/ach-requests/'+formData.humanReadableId+'/CompleteApproval';
    console.log('in submitAchConfirmFamis, formData:', formData, 'endpoint:', endpoint);
    try {
      const data = await this.$axios.patch(endpoint);
      console.log('store/ach.js submitAchConfirmFamis is running, data:', data);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },

  async submitAchTransferRequestToOther({ commit }, formData) {
    console.log('submitAchTransferRequestToOther, formData:', formData);
    let endpoint = '/ach-requests/'+formData.humanReadableId+'/TransferDepartment?departmentId='+formData.departmentId;
    try {
      const data = await this.$axios.patch(endpoint);
      // const data = await this.$axios.patch(endpoint, { "achRequestId": formData.humanReadableId, "departmentId": formData.departmentId });
      return data;
    } catch (err) {
      // this.$defaultResponseController(err);
      return err;
    }
  },

  async submitAchTransferRequestToFinance({ commit }, formData) {
    console.log('submitAchTransferRequestToFinance, formData:', formData);
    let endpoint = '/ach-requests/'+formData.humanReadableId+'/TransferToFinance';
    // let endpoint = '/ach-requests/TransferDepartment';
    try {
      const data = await this.$axios.patch(endpoint);
      return data;
    } catch (err) {
      // this.$defaultResponseController(err);
      return err;
    }
  },

  async submitAchSendBackToDept({ commit }, formData) {
    console.log('submitAchSendBackToDept, formData:', formData, 'formData.achRequestId:', formData.achRequestId);
    let endpoint = '/ach-requests/'+formData.achRequestId+'/ReturnAchRequestToDepartment';
    try {
      const data = await this.$axios.patch(endpoint, { "achRequestId": formData.achRequestId, "note": formData.note });
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      // throw new Error(err);
    }
  },

  async setAchRequestStatus({ commit }, formData) {
    let transferEndpoint = '/ach-requests/TransferDepartment';
    console.log('in ach.js setAchRequestStatus starting, formData:', formData, 'transferEndpoint:', transferEndpoint);
    try {
      const response = await this.$axios.put(transferEndpoint, { "achRequestId": formData.humanReadableId, "departmentId": "cee19b05-b56c-48ee-b1a1-a4c4b60ff69c" });
      console.log('in ach.js setAchRequestStatus, after transfer dept, response:', response);
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }

    let statusEndpoint = '/ach-requests/setRequestStatus';
    console.log('in ach.js setAchRequestStatus still going, formData:', formData, 'statusEndpoint:', statusEndpoint);
    try {
      const response = await this.$axios.put(statusEndpoint, { "humanReadableId": formData.humanReadableId, "status": formData.status });
      console.log('in ach.js setAchRequestStatus, after set status, response:', response);
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },

  async deleteAchRequest({ commit }, formData) {
    // let endpoint = '/ach-requests/'+formData.humanReadableId;
    let endpoint = '/ach-requests/';
    try {
      console.log('store/ach.js deleteAchRequest is running, formData:', formData);
      const response = await this.$axios.delete(endpoint, {
        data: { "humanReadableId": formData.humanReadableId, "reason": formData.reason },
      });
      console.log('in ach.js setAchRequestStatus, after set status, response:', response);
      return response;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },
};

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
};
