const state = () => ({
  submittedInvoiceData: null,
});

const mutations = {
  setSubmittedInvoiceData(state, data) {
    state.submittedInvoiceData = data;
  },
};

const actions = {
  async submitInvoice({ commit }, formData) {
    try {
      const data = await this.$axios.post('/Invoices',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      commit('setSubmittedInvoiceData', data.data);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
};
