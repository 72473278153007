import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';

dayjs.extend(dayjsPluginUTC);

window.dayjs = dayjs;

const readableDates = (obj, UTCFix = false) => {
  if (Array.isArray(obj)) {
    obj.forEach(element => {
      for (let key in element) {
        if (String(key).toLowerCase().indexOf('date') === -1 || !element[key]) {
          continue;
        }
        if (UTCFix) {
          if (dayjs(element[key]).isUTC()) {
            element[key] = dayjs(element[key]).local();
          } else {
            element[key] = dayjs(`${element[key]}Z`).local();
          }
        } else {
          element[key] = dayjs(element[key]);
        }
        
      }
    });
  } else {
    for (let key in obj) {
      if (String(key).toLowerCase().indexOf('date') === -1 || !obj[key]) {
        continue;
      }
      if (UTCFix) {
        if (dayjs(obj[key]).isUTC()) {
          obj[key] = dayjs(obj[key]).local();
        } else {
          obj[key] = dayjs(`${obj[key]}Z`).local();
        }
      } else {
        obj[key] = dayjs(obj[key]);
      }
    }
  }

  return obj;
};

export default {
  install: (Vue, {
    store,
  }) => {
    Vue.prototype.$dayjs = dayjs;
    Vue.dayjs = dayjs;
    store.$dayjs = dayjs;
    
    store.$readableDates = readableDates;

    Vue.filter('toHumanDate', function (value) {
      if (!value) {
        return '';
      }
      return String(dayjs(value).format(`MMMM DD, YYYY \n h:mm A`)).trim();
    });
  },
};
