<template>
  <transition name="fade">
    <div
      v-if="isActive"
      class="modal is-active"
    >
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p
            v-show="localSettings.title"
            class="modal-card-title is-marginless is-size-6-mobile"
          >
            {{ localSettings.title }}
          </p>
          <button
            class="delete is-medium"
            aria-label="close"
            @click="deleteButtonClicked"
          />
        </header>
        <section class="modal-card-body is-large">
          <slot />
        </section>
        <footer
          v-if="localSettings.buttons.length > 0"
          class="modal-card-foot is-justified-end"
        >
          <button
            v-for="(button, key) in localSettings.buttons"
            :key="key"
            :class="button.class"
            class="button is-primary"
            @click.prevent="doClick(button.onClick, $event)"
          >
            {{ button.text }}
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isActive: false,
      localSettings: {
        buttons: [],
        title: null,
      },
    };
  },
  watch: {
    settings(nextSettings) {
      console.log('Modal.vue watch settings, nextSettings:', nextSettings);
      this.localSettings = Object.assign(this.localSettings, this.settings);
    },
  },
  created() {
    this.localSettings = Object.assign(this.localSettings, this.settings);
  },
  methods: {
    deleteButtonClicked() {
      this.$emit('delete-button-clicked');
      this.hide();
    },
    hide() {
      this.isActive = false;
    },
    show() {
      this.isActive = true;
    },
    doClick(onClick, event) {
      if (onClick  === 'hide') {
        this.hide();
      } else {
        onClick(event);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .modal-card-head {
    .delete {
      background-color: $primary;
    }
  }
</style>
