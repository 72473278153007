import Vue from 'vue';
import store from './store/store';
import Router from 'vue-router';
import constants from '@/config/constants';
import sCache from '@/plugins/simple-cache';
import { EventBus } from '@/plugins/bus.js';

import * as login from '@/pages/auth/Login.vue';
import * as loginWithAd from '@/pages/auth/LoginForm2.vue';

Vue.use(sCache, { store });

/**
 * Setting the current logged in user.
 */
store.commit('login/setToken');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('@/pages/Maintenance'),
      meta: {
        layout: 'full-height',
      },
    },
    {
      path: '/',
      name: 'home',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/pages/AdminLanding.vue'),
      meta: {
        requiresAuth: true,
        layout: 'full-height',
      },
    },
    {
      path: '/landing',
      name: 'landing',
      component: () => import('@/pages/VendorLanding.vue'),
      meta: {
        requiresAuth: true,
        layout: 'full-height',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import("@/pages/Users.vue"),
      meta: {
        requiresAuth: true,
        user: [ constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
      },

      children: [
        {
          path: 'vendor-users',
          name: 'vendor-users',
          meta: {
            from: 'Users',
            menu: 'Users',
            default: true,
            requiresAuth: true,
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
          },
          component: () => import('@/pages/VendorUsers.vue'),
        },
        {
          path: 'application-users',
          name: 'application-users',
          component: () => import('@/pages/VendorUsers.vue'),
          meta: {
            user: [ constants.ACHADMINISTRATOR, constants.ADMINISTRATOR ],
            from: 'Users',
            menu: 'Users',
          },
        },
        {
          path: 'admins',
          name: 'admins',
          component: () => import('@/pages/Admins.vue'),
          meta: {
            user: [ constants.ACHADMINISTRATOR, constants.ADMINISTRATOR ],
            from: 'Users',
          },
        },
      ],
    },
    {
      path: '/vendors',
      name: 'vendors',
      component: () => import("@/pages/Vendors.vue"),
      meta: {
        requiresAuth: true,
        menu: 'Vendors',
        user: [ constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
      },
    },
    {
      path: '/vendor/:id?',
      name: 'vendor',
      component: () => import("@/pages/Vendor.vue"),
      meta: {
        menu: 'Vendors',
        from: 'Vendors',
        requiresAuth: true,
      },
      children: [
        {
          path: '/vendor/:id?',
          name: 'vendor-paid',
          component: () => import("@/pages/VendorPaymentsMade.vue"),
          meta: {
            default: true,
            menu: 'Payments',
            // menu: 'Vendors',
            hideFor: [ constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
            requiresAuth: true,
          },
        },
        {
          path: '/vendor/:id?/pending',
          name: 'vendor-pending',
          component: () => import("@/pages/VendorPaymentsPending.vue"),
          meta: {
            menu: 'Vendors',
            from: 'Vendor',
            requiresAuth: true,
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
          },
        },
        {
          path: '/vendor/:id?/details',
          name: 'vendor-info',
          component: () => import("@/pages/VendorInfo.vue"),
          meta: {
            menu: 'Vendors',
            from: 'Vendor',
            requiresAuth: true,
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
          },
        },
      ],
    },
    {
      path: '/departments',
      name: 'departments',
      component: () => import('@/pages/Departments.vue'),
      meta: {
        requiresAuth: true,
        menu: 'Departments',
        user: [ constants.USER, constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
      },

      children: [
        {
          path: 'department-finance',
          name: 'department-finance',
          meta: {
            from: 'Departments',
            menu: 'City contacts',
            default: true,
            requiresAuth: true,
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
          },
          component: () => import('@/pages/DepartmentsFinance.vue'),
        },
        {
          path: 'department-invoice',
          name: 'department-invoice',
          component: () => import('@/pages/DepartmentsInvoice.vue'),
          meta: {
            // user: [ constants.ADMINISTRATOR ],
            from: 'Departments',
            menu: 'City contacts',
            requiresAuth: true,
            user: [ constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
          },
        },
      ],
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/pages/Profile.vue'),
      meta: {
        requiresAuth: true,
        menu: 'Profile',
        user: [ constants.USER, constants.ACHADMINISTRATOR, constants.ADMINISTRATOR, constants.DEPTADMIN ],
        layout: 'full-height',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: function importLoginPage(){
        let value;
        if (process.env.VUE_APP_AD_ENABLED === 'true') {
          value = loginWithAd.default;
        } else {
          value = login.default;
        }
        return value;
      }(),
      meta: {
        layout: 'full-height',
        menu: 'Login',
        user: [ null ],
      },
    },
    {
      path: '/login/email-confirmation/',
      name: 'login-email-confirmation',
      component: () => import("@/pages/auth/EmailConfirmation.vue"),
      meta: {
        layout: 'full-height',
        from: 'Login',
        user: [ null ],
      },
    },
    {
      path: '/login/send-email-confirmation/',
      name: 'send-email-confirmation',
      component: () => import("@/pages/auth/SendEmailConfirmation.vue"),
      meta: {
        layout: 'full-height',
        from: 'Login',
        user: [ null ],
      },
    },
    {
      path: '/login/forgot-password',
      name: 'forgot-password',
      component: () => import("@/pages/auth/ForgotPassword.vue"),
      meta: {
        layout: 'full-height',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import("@/pages/register/Register.vue"),
      meta: {
        layout: 'full-height',
        menu: 'Register',
        user: [ null ],
      },
    },
    {
      path: '/register/activate',
      name: 'amount',
      component: () => import("@/pages/register/RegisterAmount.vue"),
      meta: {
        layout: 'full-height',
        from: 'Register',
        user: [ null ],
      },
    },
    {
      path: '/ach-vendor-letter',
      name: 'ach-vendor-letter',
      component:  () => import('@/pages/docs/ACHVendorLetter'),
      meta: {
        layout: 'full-height',
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component:  () => import('@/pages/docs/FAQ'),
      meta: {
        layout: 'full-height',
      },
    },
    {
      path: '/invoice/form',
      name: 'invoice-form',
      component:  () => import('@/pages/invoices/Form'),
      meta: {
        requiresAuth: true,
        menu: 'Invoicing',
        user: [ constants.USER ],
      },
    },
    {
      path: '/invoice/confirmation',
      name: 'invoice-confirmation',
      component:  () => import('@/pages/invoices/Confirmation'),
      meta: {
        requiresAuth: true,
        // menu: 'Invoicing',
        user: [ constants.USER ],
      },
    },
    {
      path: '/ach/form',
      name: 'ach-form',
      component:  () => import('@/pages/ach/Form'),
      meta: {
        requiresAuth: true,
        menu: 'ACH',
        // menu: 'ACH Enrollment',
        user: [ constants.USER ],
      },
    },
    {
      path: '/ach/confirmation',
      name: 'ach-confirmation',
      component:  () => import('@/pages/ach/Confirmation'),
      meta: {
        requiresAuth: true,
        user: [ constants.USER ],
      },
    },
    {
      path: '/ach-request/',
      name: 'ach-request',
      component:  () => import('@/pages/ach-request/Select'),
      meta: {
        requiresAuth: true,
        menu: 'ACH Requests',
        user: [ constants.ACHADMINISTRATOR, constants.DEPTADMIN ],
      },
    },
    {
      path: '/ach-request/:id?',
      name: 'ach-request-by-id',
      component:  () => import('@/pages/ach-request/AchRequest'),
      meta: {
        requiresAuth: true,
        user: [ constants.USER, constants.ACHADMINISTRATOR, constants.DEPTADMIN ],
      },
      children: [
        {
          path: '/ach-request/:id?/pending',
          name: 'ach-request-pending',
          component:  () => import('@/pages/ach-request/Pending'),
          meta: {
            requiresAuth: true,
            user: [ constants.ACHADMINISTRATOR ],
          },
        },
        {
          path: '/ach-request/:id?/form',
          name: 'ach-request-form',
          component:  () => import('@/pages/ach-request/Form'),
          meta: {
            requiresAuth: true,
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.DEPTADMIN ],
          },
        },
        {
          path: '/ach-request/:id?/confirmation',
          name: 'ach-request-confirmation',
          component:  () => import('@/pages/ach-request/Confirmation'),
          meta: {
            requiresAuth: true,
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.DEPTADMIN ],
          },
        },
        {
          path: '/ach-request/:id?/denied',
          name: 'ach-request-denied',
          component:  () => import('@/pages/ach-request/Denial'),
          meta: {
            requiresAuth: true,
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.DEPTADMIN ],
          },
        },
        {
          path: '/ach-request/:id?/transfer',
          name: 'ach-request-transfer',
          component:  () => import('@/pages/ach-request/Transfer'),
          meta: {
            requiresAuth: true,
            user: [ constants.USER, constants.ACHADMINISTRATOR, constants.DEPTADMIN ],
          },
        },
        {
          path: '/ach-request/:id?/deleted',
          name: 'ach-request-deleted',
          component:  () => import('@/pages/ach-request/Deleted'),
          meta: {
            requiresAuth: true,
            user: [ constants.ACHADMINISTRATOR, constants.DEPTADMIN ],
          },
        },
        // {
        //   path: '/ach-request/:id?/transfer-other',
        //   name: 'ach-request-transfer-other',
        //   component:  () => import('@/pages/ach-request/TransferOther'),
        //   meta: {
        //     requiresAuth: true,
        //     // menu: 'Invoicing',
        //     user: [ constants.USER, constants.ADMINISTRATOR, constants.DEPTADMIN ],
        //   },
        // },
        // {
        //   path: '/ach-request/:id?/transfer-finance',
        //   name: 'ach-request-transfer-finance',
        //   component:  () => import('@/pages/ach-request/TransferFinance'),
        //   meta: {
        //     requiresAuth: true,
        //     // menu: 'Invoicing',
        //     user: [ constants.USER, constants.ADMINISTRATOR, constants.DEPTADMIN ],
        //   },
        // },
      ],
    },
  ],
});

/**
 * SET THE AUTH validations
 */

const getComponents = async (to) => {
  let components = [];
  if (to.matched.length > 0) {
    for(let i = 0; i < to.matched.length; i+=1) {
      let component = to.matched[i].components;
      if (to.matched[i].components.default !== undefined) {
        if (typeof to.matched[i].components.default === 'function') {
          component = await to.matched[i].components.default();
        }
      }
      components.push(component);
    }
  }

  return components;
};

const checkAndRunFetch = async (components, ctx) => {
  console.log('checkAndRunFetch is running, components:', components, 'ctx:', ctx);
  if (components.length > 0) {
    console.log('checkAndRunFetch components length is > 0');
    for(let i = 0; i < components.length; i++) {
      console.log('for loop i:', i);
      if (!components[i].default) {
        continue;
      }
      if (components[i].default.fetch) {
        console.log('loop i:', i, 'if 2 components[i]:', components[i], 'ctx:', ctx);
        await components[i].default.fetch(ctx);
      }
    }
    return;
  }
  console.log('checkAndRunFetch if is not running');
  return;
};

router.beforeEach(async (to, from, next) => {
  console.log('router.js function beforeEach is running, to:', to, 'from:', from, 'next:', next);
  // store.commit('setFullScreenLoading', true);

  const ctx = {
    store,
    params: to.params,
    query: to.query,
    redirect: next,
    sCache,
  };

  // const components = await getComponents(to);
  // await checkAndRunFetch(components, ctx);

  console.log('router.js beforeEach is running 2');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('router.js beforeEach if is running');
    if (!store.getters['login/isLoggedIn']) {
      // console.log('router.js beforeEach if is running, not logged in going to login');
      store.commit('login/setRouteAfterLogin', to.path);
      next({ name: 'login' });
    } else {
      // console.log('in else');
      const components = await getComponents(to);
      await checkAndRunFetch(components, ctx);

      let user = store.state.login.loggedIn;
      let type = store.state.login.loggedIn.type;
      if (Array.isArray(type)) {
        if (type.includes('AchAdministrator')) {
          type = 'AchAdministrator';
        }
      }
      console.log('user:', user, 'to:', to);
      // If path is home, then route users to the correct landing home.

      if (to.name === 'ach-request-by-id') {
        console.log('ach-request-by-id');
        next();
      } else {

        if (to.name === 'home') {
          if (constants.isAnyAdmin(user.type)) {
            next({ name: 'admin' });
          } else {
            next({ name: 'landing' });
          }
        } else {
          if (typeof to.meta.user === 'object') {
            // console.log('inside else if to.meta.user is an object');
            if (to.meta.user.indexOf(type) !== -1) {
              // console.log('inside else if if - type index is not -1');
              next();
            } else {
              // console.log('inside else if else - type index is -1');
              if (constants.isAnyAdmin(user.type)) {
                document.location = '/vendors';
              } else {
                document.location = '/vendor';
              }
            }
          } else {
            // console.log('inside else else (to.meta.user is not an object)');
            next();
          }
        }
      }

    }
  } else {
    console.log('router.js beforeEach else is running');
    next();
  }
});

export default router;
