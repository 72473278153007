<template>
  <div
    v-show="show"
    class="phila-loading-full"
  >
    <div>
      <slot>
        <h3 class="title">
          Loading...
        </h3>
      </slot>
      <img
        src="https://www.phila.gov/assets/images/city-loading-occillate.gif"
        :width="width"
        :height="height"
        class="loading-image"
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: [ String, Number ],
      default: 80,
    },
    height: {
      type: [ String, Number ],
      default: 80,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
  .phila-loading-full {
    position: fixed;
    z-index: 98;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .title {
      margin: 0.5rem 0;
      font-weight: normal;
    }
    
    .loading-image {
      margin: 0 10px;
    }

    > div {
      text-align: center;
    }
  }
</style>
