// TODO: Remove Faker Stuff
import Vue from 'vue';

const state = () => ({
  departments: [],
});

const mutations = {
  setDepartments(state, departments) {
    state.departments = departments;
  },

  updateDepartment(state, department) {
    const index = state.departments.findIndex(d => d.id === department.id);
    Vue.set(state.departments, index, { ...state.departments[index], ...department });
  },
};

const actions = {
  async getAllDepartments({ commit }) {
    console.log('store/departments.js getAllDepartments is running');
    try {
      const response = await this.$axios.get('/Departments');
      if (response.data) {
        commit('setDepartments', response.data);
      } else {
        commit('setDepartments', []);
      }
    } catch (err) {
      this.$defaultResponseController(err);
    }
  },

  async update({ commit }, payload) {
    try {
      const { data } = await this.$axios.put('/Departments', payload, { params: { departmentId: payload.id }});
      commit('updateDepartment', data);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
    }
  },
};

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
};
