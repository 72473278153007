<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-6 content">
      <!-- <div class="column is-two-fifths content"> -->
        <callout
          v-if="routeAfterLogin"
          type="info"
        >
          After logging in, you will be routed directly to {{ this.routeAfterLogin }}.
        </callout>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column login-box card is-6 content">
        <div class="p-md">
      <!-- <div class="column is-5"> -->
        <!-- <div class="login-form box"> -->
          <!-- <h2 class="title is-5 has-text-centered"> -->
          <h2 class="has-text-centered">
            Vendor Payments
          </h2>
          <p class="has-text-left">
            Vendors and foster parents can use this site to:
          </p>
          <ul class="m-t-none">
            <li>Track payments.</li>
            <li>Enroll in or change ACH payments.</li>
          </ul>
          <!-- </p> -->
          <p>
            Vendors can also send invoices through Vendor Payments.
          </p>

          <p>
            <b>To register on the vendor payment website, you’ll need information about a previous payment from the City.</b>
            If you’d like to enroll in direct deposit before you’ve received your first payment, call or email your department contact.
          </p>

          <!-- <a
            target="_blank"
            href="#"
            @click.prevent="showEnrollModal"
          >
            enroll in electronic payments
          </a>, use the form below to log in. If you are
          not registered, click on the 'Register Here' button to create an account.
          <a
            target="_blank"
            :href="$router.resolve({ name: 'ach-vendor-letter' }).href"
          >More information about electronic payments.</a>. -->
          <h3>Sign in</h3>

          <callout type="info">
            City employees should follow the link below the sign in button to sign in with their @phila.gov address.
          </callout>

          <validation-observer
            ref="login-form"
            v-slot="validation"
            tag="div"
          >
            <input-form>
              <vee-textbox
                id="email-address"
                v-model="email"
                placeholder="Email address"
                aria-required="true"
                aria-placeholder="Email address"
                rules="required"
                :value="email"
                name="Email address"
                required
              />

              <vee-textbox
                id="password"
                v-model="password"
                type="Password"
                placeholder="Password"
                required
                aria-required="true"
                aria-placeholder="Password"
                name="Password"
                rules="required"
              />

              <router-link
                :to="{ name: 'forgot-password' }"
                class="route-link"
              >
                 Forgot your password?
              </router-link>

              <div
                class="field has-text-centered m-sm"
              >
                <button
                  id="login-btn"
                  :class="{ 'is-loading' : loading }"
                  :disabled="loading"
                  class="button dark-button"
                  @click.prevent="logIn(validation)"
                >
                  Sign in
                </button>
              </div>

              <div
                class="field has-text-centered m-sm"
              >
                <button
                  id="create-account-btn"
                  :class="{ 'is-loading' : loading }"
                  :disabled="loading"
                  class="button light-button"
                  @click.prevent="createAccount"
                >
                  Create an account
                </button>
              </div>

              <div>
                <a
                  class="is-block city-login-btn route-link"
                  href="javascript:;"
                  @click.prevent="$store.dispatch('msal/startSession', { routeAfterLogin })"
                >
                  Sign in with your @phila.gov email
                </a>
              </div>

            </input-form>

          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import NewAppNotification from "@/components/NewAppNotification";
import { Textbox, Callout } from '@phila/phila-ui';
import InputForm from '@/components/InputForm';

import {
  extend,
  withValidation,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: 'LoginPage2',
  components: {
    VeeTextbox,
    Callout,
    InputForm,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      email: '',
      password: '',
      loggingIn: false,
      // loginInfo: {
      //   email: '',
      //   password: '',
      // },
    };
  },
  computed: {
    routeAfterLogin() {
      let value;
      if (this.$store.state.login.routeAfterLogin && this.$store.state.login.routeAfterLogin !== '/') {
        value = window.location.origin + this.$store.state.login.routeAfterLogin;
      }
      return value;
    },
  },
  created () {
    // console.log('LoginForm2.vue created is running');
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
      message: (_, values) => 'the Email address field must be a valid email',
    });
  },
  mounted() {
    if (this.$route.query.redirected === "yes") {
      this.$modal.hide();
      this.$modal.show(NewAppNotification);
    }

    console.log('Login.vue this.$sCache.get(idle-logout):', this.$sCache.get('idle-logout'));

    if (this.$sCache.get('idle-logout')) {
      this.$modal.show('dialog', {
        title: 'Idle',
        text: `Your session has expired due to an extended period of inactivity`,
        buttons: [
          {
            title: 'Ok',
            default: true,
            handler: () => {
              this.$sCache.set('idle-logout', false);
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    }
  },
  methods: {
    createAccount() {
      this.$router.push('register');
    },
    showEnrollModal() {
      // console.log('showEnrollModal is running');
      this.$modal.show('dialog', {
        title: 'Electronic Payments (ACH)',
        text: `The City of Philadelphia encourages all vendors to enroll in electronic payments.
          </br>Electronic payment provides vendors immediate access to funds through a more secure method of payment than a paper check.`,
        buttons: [
          {
            title: 'Ok',
            default: true,
            close: true,
          },
        ],
      });
    },
    async logIn(validation) {
      // console.log('logIn running, validation:', validation);
      const valid = await validation.validate();
      if (!valid) {
        return false;
      }

      this.loggingIn = true;
      const result = await this.$store.dispatch('login/startSession', {
        email: this.email,
        password: this.password,
      });

      this.loggingIn = false;
      if (result !== false) {
        result.data.email = this.email;
        result.data.routeAfterLogin = this.$store.state.login.routeAfterLogin;
        this.$store.dispatch('login/login', result.data);
      }
    },
  },
};
</script>
<style lang="scss" scoped>

  .login-form {
    margin: 2rem 0;
    border: 1px solid $sidewalk-grey;
    padding: 2rem 1rem 1rem;
  }
  .city-login-btn {
    margin-top: 1rem;
  }

  .route-link {
    font-size: .9rem;
  }

  .dark-button {
    width: 200px;
    color: $white;
    background-color: $ben-franklin-blue-dark;
  }

  .light-button {
    width: 200px;
    color: $ben-franklin-blue-dark;
    background-color: $white;
    border-width: 2px;
    border-color: $ben-franklin-blue-dark
  }


</style>
