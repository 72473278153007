<template>
  <form
    :id="`form-${id}`"
    v-bind="$attrs"
    novalidate
  >
    <h3
      v-if="title"
      class="title is-4 form-padding"
    >
      {{ title }}
    </h3>
    <div
      v-if="desc"
      class="subtitle is-5 form-padding"
    >
      {{ desc }}
    </div>
    <!-- @slot The form inputs -->
    <slot />
    <div
      v-if="!hideErrorsCount && errorsCount > 0"
      class="form-errors has-text-centered has-text-weight-bold has-text-love-park-red-dark"
    >
      <span class="icon">
        <i class="fa fa-exclamation-triangle" />
      </span>
      <span>
        <i18n
          path="form.formErrorsCountMsg"
        >
          <template v-slot:errorCount>
            {{ errorsCount }}
          </template>
        </i18n>
        <!-- {{ $t('form.formErrorsCountMsg') }} -->
      </span>
    </div>
    <template v-if="$slots['submit']">
      <div class="form-submit form-padding">
        <!-- @slot Submit button placement. For standard forms, it's best to use this slot to keep the submit button as the last element in the form, bellow the errors count). -->
        <slot name="submit" />
      </div>
    </template>
  </form>
</template>
<script>

import Vue from 'vue';
import { InputForm } from '@phila/phila-ui';

export default {
  extends: InputForm,
};

</script>