import Vue from 'vue';

const mutations = {
  setUnits(state, units) {
    state.units = units;
  },

  addUnit(state, unit) {
    const departmentIndex = this.state.departments.departments.findIndex(d => d.id === unit.departmentId);
    const unitIndex = this.state.departments.departments[departmentIndex].units.length;
    Vue.set(this.state.departments.departments[departmentIndex].units, unitIndex, { ...unit });
  },

  updateUnit(state, unit) {
    const departmentIndex = this.state.departments.departments.findIndex(d => d.id === unit.departmentId);
    const unitIndex = this.state.departments.departments[departmentIndex].units.findIndex(d => d.id === unit.id);
    const replacementUnit = {
      departmentId: unit.departmentId,
      id: unit.id,
      invoiceContactId: unit.invoiceContactId,
      invoiceContactInfo: {
        contactName: unit.invoiceContactInfo.contactName,
        emailAddress: unit.invoiceContactInfo.emailAddress,
      },
      isDeleted: unit.isDeleted,
      unitName: unit.unitName,
    };
    Vue.set(this.state.departments.departments[departmentIndex].units, unitIndex, { ...replacementUnit });
  },

  deleteUnit(state, unit) {
    const departmentIndex = this.state.departments.departments.findIndex(d => d.id === unit.departmentId);
    const unitIndex = this.state.departments.departments[departmentIndex].units.findIndex(d => d.id === unit.unitId);
    Vue.delete(this.state.departments.departments[departmentIndex].units, unitIndex);
  },
};

const actions = {
  async add({ commit }, payload) {
    try {
      if (payload.unitName == '') {
        payload.unitName = 'no unit name';
      }
      const { data } = await this.$axios.post(`/Departments/${payload.departmentId}/Units`, payload);
      commit('addUnit', data);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
    }
  },

  async update({ commit }, payload) {
    try {
      const { data } = await this.$axios.put('/Departments/Units', payload, { params: { unitId: payload.unitId }});
      commit('updateUnit', data);
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
    }
  },

  async delete({ commit }, payload) {
    try {
      await this.$axios.delete('/departments/Units', { params: { unitId: payload.unitId }});
      commit('deleteUnit', payload);
      return payload;
    } catch (err) {
      this.$defaultResponseController(err);
    }
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
};
