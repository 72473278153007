// import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
  vendors: [],
  vendor: null,
  paidPayments: [],
  pendingPayments: [],

  paymentDetailsCache: {},
  pendingPaymentsCache: {},
  paidPaymentsCache: {},
});

const getters = {
};

const mutations = {
  setVendors(state, vendors) {
    state.vendors = vendors;
  },
  setVendor(state, vendor) {
    state.vendor = vendor;
  },
  setPaidPayments(state, payments) {
    state.paidPayments = payments;
  },
  setPendingPayments(state, payments) {
    state.pendingPayments = payments;
  },

  setPendingPaymentsCache(state, payload) {
    state.pendingPaymentsCache[payload.key] = payload.payments;
  },
  setPaidPaymentsCache(state, payload) {
    state.paidPaymentsCache[payload.key] = payload.payments;
  },
  setPaymentDetailsCache(state, payload) {
    state.paymentDetailsCache[payload.key] = payload.payments;
  },
};

const actions = {
  async getVendor({ commit }, uuid) {
    let endpoint = '/Vendors';
    if (uuid) {
      endpoint += `/${uuid}`;
    }

    const fromCache = this.$sCache.get(`vendor-${uuid}`);
    if (fromCache) {
      commit('setVendor', fromCache);
      return fromCache;
    }

    try {
      const response = await this.$axios.get(endpoint);
    
      if (response.data) {
        commit('setVendor', response.data);
        this.$sCache.set(`vendor-${uuid}`, response.data, 'fiveminutes');
      } else {
        commit('setVendor', []);
      }
      return response;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },
  async getAllVendors({ commit }) {
    try {
      const response = await this.$axios.get('/vendors/all');
      if (response.data.length > 0) {
        commit('setVendors', response.data);
      } else {
        commit('setVendors', [ response.data ]);
      }
      return response;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },
  async getVendors({ commit }, params) { // eslint-disable-line
    const query = {
      SearchString: params.query,
      SearchType: params.filterBy,
      Ascending: !!params.ascending,
      PageNumber: params.page,
      PageSize: params.limit,
    };

    try {
      const response = await this.$axios.get('/Vendors/Search', { params: query });
      return response;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },
  async getPaidPayments({ state, commit }, uuid) {
    const cacheName = uuid || 'own';
    if (state.paidPaymentsCache[cacheName]) {
      commit('setPaidPayments', state.paidPaymentsCache[cacheName]);
      return true;
    }

    let endpoint = '/vendors/Payments/paid';
    if (uuid) {
      endpoint += `/${uuid}`;
    }

    try {
      const response = await this.$axios.get(endpoint);

      if (response.data.length > 0) {
        // Fix date
        response.data = this.$readableDates(response.data);

        commit('setPaidPayments', response.data);
        commit('setPaidPaymentsCache', {
          key: cacheName,
          payments: response.data,
        });
      } else {
        commit('setPaidPayments', []);
      }
      return true;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },
  async getPendingPayments({ state, commit }, uuid) {
    const cacheName = uuid || 'own';
    if (state.pendingPaymentsCache[cacheName]) {
      commit('setPendingPayments', state.pendingPaymentsCache[cacheName]);
      return true;
    }

    let endpoint = '/vendors/Payments/pending';
    if (uuid) {
      endpoint += `/${uuid}`;
    }
    try {
      const response = await this.$axios.get(endpoint);

      if (response.data.length > 0) {
        response.data = this.$readableDates(response.data);

        commit('setPendingPayments', response.data);
        commit('setPendingPaymentsCache', {
          key: cacheName,
          payments: response.data,
        });
      } else {
        commit('setPendingPayments', []);
      }
      return true;
    } catch (err) {
      this.$defaultResponseController(err);
      throw new Error(err);
    }
  },

  async getPaymentDetails({ state, commit }, payload) { // eslint-disable-line
    // TODO: Implement Cache Here
    // const cacheName = payload.checkNumber || 'own';
    // if (state.paymentDetailsCache[cacheName]) {
    //   return state.paymentDetailsCache[cacheName];
    // }

    let endpoint = '/vendors/Payments/paid';
    if (payload.uuid) {
      endpoint += `/${payload.uuid}`;
    }
    try {
      const { data } = await this.$axios.get(endpoint, {
        params: payload,
      });

      // TODO: Implement Cache Here
      // commit('setPaymentDetailsCache', {
      //   key: cacheName,
      //   payments: data,
      // });
      return data;
    } catch (err) {
      this.$defaultResponseController(err);
      return null;
    }
  },
};

export default {
  namespaced: true,
  state: state(),
  getters,
  mutations,
  actions,
};