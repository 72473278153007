<template>
  <div
    class="modal is-medium is-active"
  >
    <div
      class="modal-background"
      @click="$emit('close');"
    />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Login failed.
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close');"
        />
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p>
            Your email and password do not match.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginError',
  created() {
    window.addEventListener('keydown', () => {
      this.$emit('close');
    });
  },
};
</script>

<style>

</style>
