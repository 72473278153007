<template>
  <component :is="layout">
    <router-view />
    <!-- <full-screen-loading :show="fullScreenLoading" /> -->

    <!-- Modals -->
    <modals-container />
    <v-dialog class="content" />

    <!-- Terms and Conditions Modal -->
    <modal
      ref="terms-and-conditions-modal"
      class="is-large"
      :settings="{ title: 'Vendor Payments Disclaimer' }"
    >
      <terms-and-conditions />
    </modal>
  </component>
</template>

<script>
import FullScreenLoading from '@/components/common/FullScreenLoading';
import Modal from '@/components/common/Modal';
import TermsAndConditions from '@/components/TermsAndConditions';

import RowComponent from '@/components/RowComponent';
import { EventBus } from '@/plugins/bus.js';

export default {
  name: "App",
  components: {
    FullScreenLoading,
    Modal,
    TermsAndConditions,
  },
  templates: {
    RowComponent,
  },
  data() {
    return {
      idlePopupTimer: null,
      default_layout: "default",
    };
  },
  computed: {
    fullScreenLoading() {
      return this.$store.state.fullScreenLoading;
    },
    layout() {
      if (this.$route.meta.layout === this.default_layout) {
        return this.default_layout;
      }
      return (this.$route.meta.layout || this.default_layout) + "-layout";
    },
  },
  async fetch({ store }) {
    await store.dispatch('users/getCurUserData');
    return Promise.resolve();
  },
  created() {
    console.log('App.vue created is running, process.env.VUE_APP_API_ROOT:', process.env.VUE_APP_API_ROOT);
    this.$store.dispatch('login/silentlyLoginTimeout');

    EventBus.$on('show-terms-and-conditions', this.showTermsAndConditions);
  },
  mounted() {
    const flashMessage = this.$sCache.get('flash-message');
    if (flashMessage !== null) {
      const type = flashMessage.type ? `$${flashMessage.type}`: '$warning';
      this[type](flashMessage.message);
      this.$sCache.clear('flash-message');
    }

    setTimeout(() => {
      this.connectionStatus();
    }, 3500);
  },
  methods: {
    showTermsAndConditions() {
      console.log('App.vue showTermsAndConditions is running');
      this.$refs['terms-and-conditions-modal'].show();
    },
    clearIdlePopupTimer() {
      clearTimeout(this.idlePopupTimer);
    },
    idleLogout() {
      console.log('idleLogout is running');
      this.clearIdlePopupTimer();
      this.$sCache.set('idle-logout', true);
      this.$store.dispatch('login/logOut');
    },
    connectionStatus() {
      // TODO: This code is duplicated in maintenance.
      if (navigator.onLine === false) {
        EventBus.$emit('offline');
      } else if (navigator.onLine === true) {
        EventBus.$emit('online');
      }
    },
  },
  onActive() {
    this.clearIdlePopupTimer();
  },
  onIdle() {
    if (this.isLoggedIn) {
      this.$modal.show('dialog', {
        title: 'Idle',
        text: `Are you still there?`,
        buttons: [
          {
            title: 'Yes',
            handler: () => {
              this.clearIdlePopupTimer();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Logout',
            default: true,
            handler: () => {
              this.$modal.hide('dialog');
              this.idleLogout();
            },
          },
        ],
      });

      this.idlePopupTimer = setTimeout(() => {
        this.idleLogout();
      }, 15000);
    }
  },
};
</script>

<style lang="scss">
  @import "./assets/scss/main.scss";
</style>
