<template>
  <div
    id="app"
    class="login-page"
  >
    <app-header
      class="long-title-menu-down"
      app-title="Vendor Payments"
    >
      <tabs-nav
        v-if="!routeHideTabsNav.includes($route.name)"
        slot="tabs-nav"
      >
        <router-link
          v-for="(menu, index) in menuOptions"
          :key="index"
          :to="{ name: menu.href }"
          :class="{ 'is-active': currentMenus.includes(menu.text) }"
        >
          {{ menu.text }}
        </router-link>
      </tabs-nav>
      <dropdown-nav
        v-if="Object.keys(this.loggedIn).length"
        slot="dropdown-nav"
        :nav="myDropdownNavLink"
      />

      <mobile-nav
        slot="mobile-nav"
        :links="allLinks"
      >
      </mobile-nav>

      <div
        v-if="$route.name === 'maintenance'"
        class="maintenance-icon navbar-item"
      >
        <i
          class="fad fa-exclamation-triangle"
        />
      </div>
    </app-header>

    <main
      :class="{ 'login-main': !isLogin, 'vendor-user-main': isVendorUser }"
      class="main section"
    >
      <slot />
    </main>

    <app-footer
      :links="footerLinks"
      :is-sticky="true"
    />
  </div>
</template>
<script>

import {
  AppHeader,
  TabsNav,
  DropdownNav,
  MobileNav,
  AppFooter,
} from '@phila/phila-ui';

import { EventBus } from '@/plugins/bus.js';

export default {
  name: "Hero",
  components: {
    AppHeader,
    TabsNav,
    DropdownNav,
    MobileNav,
    AppFooter,
  },
  data() {
    return {
      menuOptions: [],
      routeHideTabsNav: [
        'login',
        'maintenance',
        'faq',
        'ach-vendor-letter',
      ],
      myDropdownNavLink: {
        button: this.$store.state.login.loggedIn.firstName + ' ' + this.$store.state.login.loggedIn.lastName,
        icon: true,
        links: [
          {
            href: "profile",
            text: "Profile",
          },
          {
            type: 'native',
            text: "Logout",
            click: () => {
              this.$store.dispatch('login/logOut');
            },
          },
        ],
      },
      footerLinks: [
        {
          type: 'native',
          text: 'Terms of Use',
          click: () => {
            EventBus.$emit('show-terms-and-conditions');
          },
        },
        {
          text: 'FAQ',
          href: 'faq',
          attrs: {
            target: '_blank',
          },
        },
      ],
    };
  },
  computed: {
    isLogin() {
      let value = true;
      if (!Object.keys(this.loggedIn).length) {
        value = false;
      }
      return value;
    },
    isVendorUser() {
      let value = false;
      if (this.curUserType === 'User') {
        return true;
      }
      return value;
    },
    currentMenus() {
      return this.$route.matched.map((r) => {
        return r.meta.menu || r.meta.from || '';
      });
    },
    allLinks() {
      return this.menuOptions.concat(this.footerLinks).concat(this.myDropdownNavLink.links);
    },
    achFormEnabled() {
      let value;
      // console.log('process.env.VUE_APP_ACH_FORM_ENABLED:', process.env.VUE_APP_ACH_FORM_ENABLED);
      if (process.env.VUE_APP_ACH_FORM_ENABLED === 'true') {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
    achRequestEnabled() {
      let value;
      if (process.env.VUE_APP_ACH_REQUEST_ENABLED === 'true') {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
  },
  watch: {
    "$route"() {
      this.calculateMenu();
    },
  },
  beforeMount() {
    this.calculateMenu();
    // const html = document.getElementsByTagName('html')[0].classList.add('is-login');
  },
  methods: {
    calculateMenu() {
      const currentRoute = this.$route.name || '';

      let allRoutes = this.getRoutes(this.$router.options.routes);
      let menuInRoutes = allRoutes.filter(route => (route.meta) ? !!route.meta.menu : false);

      menuInRoutes = menuInRoutes.filter((route) => {
        // TODO: Removing hardcoding for this validation and made it correctly
        if (route.meta.hideFor) {
          if (route.meta.hideFor.indexOf(this.curUserType) !== -1) {
            return false;
          }
        }

        if (typeof route.meta.user === 'object') {
          return route.meta.user.indexOf(this.curUserType) !== -1;
        }

        if (route.meta.requiresAuth === true) {
          console.log("resolve as: ", (this.isLoggedIn && currentRoute !== 'login'));
          return this.isLoggedIn && currentRoute !== 'login';
        }

        return true;
      });

      // console.log('layouts/FullHeight.vue calculateMenu is running, allRoutes:', allRoutes, 'menuInRoutes:', menuInRoutes);

      // sets order of menu options
      this.menuOptions = [];

      if (menuInRoutes.filter(x => x.name === 'vendor-paid')[0]) {
        let vendorPaid = {
          href: 'vendor-paid',
          text: menuInRoutes.filter(x => x.name === 'vendor-paid')[0].meta.menu,
        };
        this.menuOptions.push(vendorPaid);
      }

      if (menuInRoutes.filter(x => x.name === 'invoice-form')[0]) {
        let invoiceForm = {
          href: 'invoice-form',
          text: menuInRoutes.filter(x => x.name === 'invoice-form')[0].meta.menu,
        };
        this.menuOptions.push(invoiceForm);
      }

      if (this.achFormEnabled) {
        if (menuInRoutes.filter(x => x.name === 'ach-form')[0]) {
          let achForm = {
            href: 'ach-form',
            text: menuInRoutes.filter(x => x.name === 'ach-form')[0].meta.menu,
          };
          this.menuOptions.push(achForm);
        }
      }

      if (menuInRoutes.filter(x => x.name === 'vendors')[0]) {
        let vendors = {
          href: 'vendors',
          text: menuInRoutes.filter(x => x.name === 'vendors')[0].meta.menu,
        };
        this.menuOptions.push(vendors);
      }

      if (menuInRoutes.filter(x => x.name === 'department-finance')[0]) {
        let departmentFinance = {
          href: 'department-finance',
          text: menuInRoutes.filter(x => x.name === 'department-finance')[0].meta.menu,
        };
        this.menuOptions.push(departmentFinance);
      }

      if (menuInRoutes.filter(x => x.name === 'vendor-users')[0]) {
        let vendorUsers = {
          href: 'vendor-users',
          text: menuInRoutes.filter(x => x.name === 'vendor-users')[0].meta.menu,
        };
        this.menuOptions.push(vendorUsers);
      }

      if (this.achRequestEnabled) {
        if (menuInRoutes.filter(x => x.name === 'ach-request')[0]) {
          let achForm = {
            href: 'ach-request',
            text: menuInRoutes.filter(x => x.name === 'ach-request')[0].meta.menu,
          };
          this.menuOptions.push(achForm);
        }
      }
    },
    getRoutes(array) {
      let objects = [];
      array.forEach((arr) => {
        if (arr.children) {
          objects = objects.concat(this.getRoutes(arr.children));
        } else {
          objects.push(arr);
        }
      });
      return objects;
    },
  },
};
</script>
<style lang="scss">

#app {

  .login-main {
    padding-top: 20px !important;
    margin-top: 0px !important;
  }

  .vendor-user-main {
    padding-top: 20px !important;
    margin-top: 60px !important;
  }

  .payments-main {
    padding-top: 20px !important;
    margin-top: 0px !important;
  }

  .navbar {
    .maintenance-icon {
      font-size: 2rem;
      color: #ffefa2;
    }
  }
  position: relative;
  &.login-page {
    &:after {
      content: "";
      background-image: url(./../assets/images/login-background.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: fixed;
      z-index: -1;
    }
    // height: 100vh;
    background: rgba(255, 255, 255, 0.8);
  }
}
</style>
