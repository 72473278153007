const TIME_PREFIX = '_expire_at_';
const AN_HOUR = 60;
const TIME_OPTIONS = {
  'day': (24 * AN_HOUR),
  'halfday': (12 * AN_HOUR),
  'hour': AN_HOUR,
  'halfhour': (0.5 * AN_HOUR),
  'fiveminutes': 5,
};

const getPrefixedParam = (param) => {
  return String(param + TIME_PREFIX);
};

const clearValues = (param) => {
  try {
    localStorage.removeItem(String(param));
    localStorage.removeItem(getPrefixedParam(param));
  } catch (err) {
    // Done.
    console.warn("Error deleting the local storage", err);
  }
};

const getValue = (param) => {
  let expire = localStorage.getItem(getPrefixedParam(param));
  if (!expire) {
    clearValues(param);
    return null;
  }

  const now = new Date();
  if (expire < now.getTime()) {
    clearValues(param);
    return null;
  }

  let rawValue = localStorage.getItem(String(param));
  if (!rawValue) {
    clearValues(param);
    return null;
  }

  try {
    rawValue = JSON.parse(rawValue);
    return rawValue;
  } catch (err) {
    clearValues(param);
    console.error("Error getting the local storage", err);
    return null;
  }
};

const setValue = (name, value, expireAt) => {
  clearValues(name);

  let timeStamp = 0;

  try {
    if (expireAt instanceof Date) {
      timeStamp = expireAt.getTime();
    } else {
      const now = new Date();
      let _minutes = AN_HOUR;
  
      if (typeof TIME_OPTIONS[ expireAt ] === 'number') {
        _minutes = TIME_OPTIONS[ expireAt ];
      } else if (expireAt) {
        _minutes = parseInt(AN_HOUR);
      }
      timeStamp = now.getTime() + (_minutes * 60 * 1000);
    }

    const stringifyValue = JSON.stringify(value);
    if (stringifyValue === undefined) {
      throw 'I can\'t store that';
    }

    localStorage.setItem(String(name), stringifyValue);
    localStorage.setItem(getPrefixedParam(name), timeStamp);
    return value;
  } catch (err) {
    console.warn("Error setting the local storage", err);
    throw 'Error storing the value ' + err;
  }
};

const sCache = {
  set: setValue,
  get: getValue,
  clear: clearValues,
};

sCache.install = (Vue, { store }) => {
  Vue.prototype.$sCache = sCache;
  if (store) {
    store.$sCache = sCache;
  }
};

export default sCache;