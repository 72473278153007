const ACHADMINISTRATOR = 'AchAdministrator';
const ADMINISTRATOR = 'Administrator';
const DEPTADMIN = 'DepartmentalAdministrator';
const USER = 'User';

const isAnyAdmin = (userType) => {
  // let value = { ...userType };
  // console.log('isAnyAdmin is running, userType:', userType, 'value:', value);
  let value;
  if (Array.isArray(userType)) {
    if (userType.includes('AchAdministrator')) {
      value = 'AchAdministrator';
    }
  } else {
    value = userType;
  }
  return [ ACHADMINISTRATOR, ADMINISTRATOR, DEPTADMIN ].includes(value);
};

export default {
  ACHADMINISTRATOR,
  ADMINISTRATOR,
  DEPTADMIN,
  USER,
  isAnyAdmin,
  install(Vue, { store }) {
    Vue.prototype.$ACHADMINISTRATOR = ACHADMINISTRATOR;
    Vue.prototype.$ADMINISTRATOR = ADMINISTRATOR;
    Vue.prototype.$DEPTADMIN = DEPTADMIN;
    Vue.prototype.$USER = USER;
    Vue.prototype.$isAnyAdmin = isAnyAdmin;

    store.$ACHADMINISTRATOR = ACHADMINISTRATOR;
    store.$ADMINISTRATOR = ADMINISTRATOR;
    store.$DEPTADMIN = DEPTADMIN;
    store.$USER = USER;
    store.$isAnyAdmin = isAnyAdmin;
  },
};
