<template>
  <div class="section content">
    <h2>Disclaimer</h2>

    <h3>ADDITIONAL TERMS OF USE FOR THE VENDOR INFORMATION SITE:</h3>
    <p>
      PLEASE READ THE <a
        href="https://www.phila.gov/tos/index.html"
        target="_blank"
      ><strong>PHILA.GOV TERMS OF USE</strong></a> AND THE FOLLOWING ADDITIONAL TERMS OF USE CAREFULLY BEFORE USING THE VENDOR INFORMATION SITE.  CLICKING ON THE BOX "I AGREE," AT THE BOTTOM OF THIS PAGE SIGNIFIES THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO THE PHILA.GOV TERMS OF USE AND THE ADDITIONAL TERMS BELOW.  IF YOU DO NOT AGREE TO THE PHILA.GOV TERMS OF USE AND THE ADDITIONAL TERMS, DO NOT ACCESS OR USE THE VENDOR INFORMATION SITE.  PHILA.GOV TERMS OF USE AND THESE ADDITIONAL TERMS OF USE ARE SUBJECT TO CHANGE BY THE CITY AT ITS SOLE DISCRETION AT ANY TIME.  USE OF THIS SITE AFTER SUCH CHANGES ARE IMPLEMENTED CONSTITUTES YOUR ACCEPTANCE OF THE CHANGES.  PLEASE CONSULT ALL TERMS OF USE REGULARLY.
    </p>

    <h4>Office of the Director of Finance Records and Information</h4>
    <p>The City of Philadelphia and its Office of the Director of Finance (collectively the “City”) makes every effort to produce and publish on the Vendor Information Site (the “VIS” or “Site”) the most accurate and current information available to it. Our accounts payable records are based on invoices and vouchers as recorded with the City’s accounting system and we revise these records for accuracy as often as we are able. You are responsible, however, for verifying the accuracy of the information on this Site by referring to corresponding invoices, vouchers and purchase orders.  This information is intended to be used only by persons doing business with the City for the purpose of ascertaining the status of invoices to the City and payments made by the City.</p>

    <h4>Disclaimer</h4>
    <p>THE CITY  MAKES NO WARRANTY OR REPRESENTATION WHATSOEVER, EXPRESS OR IMPLIED, WITH RESPECT TO THE QUALITY, CONTENT, ACCURACY, COMPLETENESS, CURRENCY, FREEDOM FROM COMPUTER VIRUS, SUITABILITY FOR ANY PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF PROPRIETARY RIGHTS, OF ANY OF THE INFORMATION PUBLISHED ON THE VIS, OR ANY OF THE DESIGN, TEXT, GRAPHICS, IMAGES, PAGES, INTERFACES, LINKS, SOFTWARE, OR OTHER MATERIALS, DOCUMENTS, AND ITEMS CONTAINED IN OR DISPLAYED OR PUBLISHED ON THE VIS. ALL SUCH ITEMS AND MATERIALS ARE PROVIDED ON AN "AS IS" BASIS, AND YOU ARE FULLY AND SOLELY RESPONSIBLE FOR YOUR USE OF THEM AND FOR ANY RESULTS OR CONSEQUENCES OF YOUR USE. THE INFORMATION PROVIDED ON THE VIS MAY HAVE BEEN COMPILED FROM A VARIETY OF SOURCES, INCLUDING SOURCES BEYOND THE CONTROL OF THE CITY, AND ARE SUBJECT TO CHANGE WITHOUT NOTICE FROM THE CITY. EXCEPT AS STATED IN THESE TERMS OF USE, COMMERCIAL USE (INCLUDING BUT NOT LIMITED TO SALE TO OTHERS) IS PROHIBITED WITHOUT THE PRIOR WRITTEN PERMISSION OF THE CITY.</p>

    <p>TO THE EXTENT YOU USE, APPLY, OR IMPLEMENT THE INFORMATION PROVIDED ON THE VIS IN YOUR OWN INFORMATION SYSTEM OR OTHER SETTING, OR OTHERWISE FOR YOUR OWN PURPOSES, YOU DO SO AT YOUR OWN RISK. IN NO EVENT SHALL THE CITY, ITS AGENCIES, OFFICERS, EMPLOYEES, AGENTS, OR REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES ARISING FROM YOUR ACCESSING, OBTAINING OR USING THE VIS OR ACCESSING, OBTAINING OR USING ANY INFORMATION DISPLAYED ON OR CONTAINED IN THE VIS, OR OTHERWISE ARISING FROM THE VIS.</p>

    <p>Nothing contained in or displayed on this Site constitutes or is intended to constitute legal advice by the City or any of its agencies, officers, employees, agents, attorneys, or representatives.</p>

    <h4>Inspection and Copying of Public Records</h4>
    <p>In strict accordance with the Pennsylvania Right to Know Act, 65 P.S. § 66.1, et seq., to the extent that any documents, materials or information available on or through the VIS are deemed Public Records as defined in 65 P.S. § 66.1, the City shall follow all applicable laws concerning the inspection and copying of such documents, materials and information.</p>

    <h4>Intellectual Property</h4>
    <p>Service marks and trademarks contained in or displayed on the VIS are the property of their respective owners.  The copyrightable content of the VIS, including the design, information, text, graphics, images, pages, interfaces, links, software, and other items and materials contained in, made available or displayed on the Site and the selection and arrangements thereof (hereinafter referred to collectively as the “Content”), is copyrighted and the property of the City of Philadelphia.  All rights are reserved.  Accordingly, except where noted in these terms of use and elsewhere on the Site, and subject to applicable law, the Content of VIS may not be copied, distributed, republished, uploaded, posted, or transmitted in any way without the prior written consent of the City, except that permission is granted for you to view the Content and save or print Content only for your personal use or your company’s records, but not to further copy, modify, use or distribute the contents in any way without the permission of the City.  Such Content must remain exactly as presented on the VIS, without additions, deletions, or modifications of any kind.  You may not remove or alter, or cause to be removed or altered, any copyright, trademark, trade name, service mark, or any other proprietary notice or legend appearing on any of the Content.  Neither title nor intellectual property rights are transferred to you by access to this site.  This Site may contain some third party images and graphics that are used by permission.  VIS users are notified, therefore, that one should presume the need to obtain permission from the copyright holder before reproducing or otherwise using any images or graphics from the Site.</p>

    <h4>Communications through the site</h4>
    <p>In no event shall any communication made through any e-mail, messaging or other communications functions of the VIS constitute legal or other notice to the City, its officers, employees, agents, or representatives, including but not limited to (i) legal notice required by federal, state, or local laws, rules, or regulations with respect to any existing or potential claim or cause of action against the City or any of its agencies, officers, employees, agents, or representatives; (ii) any form of notice required pursuant to any City regulation or policy or any federal, state, or local law applying to the City; or (iii) any form of notice required pursuant to any document issued by the City.</p>

    <h4>Miscellaneous</h4>
    <p>The phila.gov Terms of Use and the Additional Terms of Use and all disputes arising under them shall be governed, construed and decided in accordance with the laws of the Commonwealth of Pennsylvania. Any lawsuit, action, claim or legal proceeding arising out of or related to these Terms of Use shall be brought exclusively in the United States District Court for the Eastern District of Pennsylvania or the Court of Common Pleas of Philadelphia County and jurisdiction over any such lawsuit, action, claim, or legal proceeding shall lie exclusively in either of these two forums.  To the extent that the any of the phila.gov Terms of Use conflict with these Additional Terms of Use, these Additional Terms of Use shall control.  Headings are for convenience only and in no way define, limit, extend or describe the intent of any provision of these Terms of Use and Disclaimer.</p>

    <p><strong>TO ACCESS OUR RECORDS NOW, PLEASE CLICK ON THE BOX "I accept the Terms and Conditions" WHICH SIGNIFIES THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO THE PHILA.GOV TERMS OF USE AND THESE ADDITIONAL TERMS OF USE.</strong></p>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
};
</script>

<style>

</style>