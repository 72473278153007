<template>
  <div>
    <template
      slot="department"
      slot-scope="props"
    >
      <p v-if="curUserType === $ADMINISTRATOR">
        <a @click.prevent="editDepartment(props.row)">
          {{ props.row.name }}
        </a>
      </p>
      <p v-else>
        {{ props.row.name }}
      </p>
    </template>
    <template
      slot="contactName"
      slot-scope="props"
    >
      {{ props.row.defaultInvoiceContactName }}
    </template>
    <template
      slot="contactPhoneNumber"
      slot-scope="props"
    >
      <a :href="`tel:+1-${props.row.contactPhoneNumber}`">{{ props.row.contactPhoneNumber }}</a>
    </template>
    <template
      slot="contactEmail"
      slot-scope="props"
    >
      <p v-if="curUserType === $ADMINISTRATOR">
        {{ props.row.defaultInvoiceContactEmail }}
      </p>
      <p v-else>
        <a
          :href="`mailto:${props.row.defaultInvoiceContactEmail}?subject=${encodeURIComponent('Payment Information Request')}`"
          targer="_blank"
        >
          {{ props.row.defaultInvoiceContactEmail }}
        </a>
      </p>
    </template>
  </div>
</template>

<script>

export default {
  name: 'RowComponent',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: [ String, Number ],
    },
  },
};
</script>
