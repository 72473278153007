import Vue from 'vue';
import constants from '@/config/constants';

import LoginError from "@/components/LoginError";

const jwtDecode = require('jwt-decode');

const state = () => ({
  loggedIn: {},
  routeAfterLogin: null,

  // Silently login setup
  silentlyLoginTimeout: null,
  secondsBeforeSilentlyLogin: 60,
  killSilentlyAfterTimes: 3,
});

const getters = {
  isLoggedIn(state) {
    return state.loggedIn.token || false;
  },
  isAdmin(state) {
    console.log('state.loggedIn.type:', state.loggedIn.type);
    let value = state.loggedIn.type;
    if (Array.isArray(value)) {
      if (value.includes('AchAdministrator')) {
        value = 'AchAdministrator';
      }
    }
    return constants.ADMINISTRATOR === value || constants.ACHADMINISTRATOR === value;
  },
};

const mutations = {
  logOut(state) {
    state.loggedIn = {};
    this.$sCache.clear('loggedIn');
  },

  setRouteAfterLogin(state, data) {
    console.log('store/login.js setRouteAfterLogin is running, data:', data);
    state.routeAfterLogin = data;
  },

  setToken(state) {
    let loggedIn = {};
    if (typeof state.loggedIn.token === 'string') {
      return;
    }

    try {
      loggedIn = this.$sCache.get('loggedIn');
      if (loggedIn) {
        state.loggedIn = loggedIn;
        state.isLoggedIn = true;
      }
    } catch (err) {
      console.warn(err);

      // Silence is power...
      state.loggedIn = {};
    }
  },

  updateAuth(state, payload) {
    console.log('login.js updateAuth is running');
    try {
      const decodedToken = jwtDecode(payload.token);
      console.log("login.js updateAuth decodedToken", decodedToken);

      const type = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      const timeToExpire = decodedToken.exp - decodedToken.nbf;
      const expiresAt = this.$dayjs().add(timeToExpire, 'second');
      console.log("login.js updateAuth expiresAt", expiresAt);
      // console.log("login.js updateAuth expiresAt", decodedToken.exp);

      payload.expirationTime = expiresAt.format();
      payload.type = type;
      console.log("login.js updateAuth, payload.expirationTime:", payload.expirationTime);

      let tempAuth = {};
      Object.assign(tempAuth, state.loggedIn, payload);

      console.log("login.js updateAuth, Assigned temp authentication", tempAuth);

      Vue.set(state, 'loggedIn', tempAuth);
      state.isLoggedIn = true;

      console.log("login.js updateAuth, expiresAt.toDate", expiresAt.toDate());

      this.$sCache.set('loggedIn', state.loggedIn, expiresAt.toDate());
    } catch(err) {
      console.log('login.js catch error occurred, err:', err);
      throw new Error(err);
    }
  },

  setSilentlyLoginTimeout(state, timeout) {
    state.silentlyLoginTimeout = timeout;
  },

  updateKillSilentlyAfterTimes(state) {
    state.killSilentlyAfterTimes--;
  },
};

const actions = {
  login({ commit, dispatch }, payload) {
    console.log("login.js login is running, payload", payload);
    const doNotRedirect = payload.doNotRedirect || false;
    if (doNotRedirect) {
      payload.doNotRedirect = null;
    }

    try {
      // console.log('login.js login is calling updateAuth');
      commit('updateAuth', payload);

      if (doNotRedirect) {
        dispatch('silentlyLoginTimeout');
      }

      if (!doNotRedirect) {
        if (payload.routeAfterLogin) {
          // console.log('login.js is routing to:', payload.routeAfterLogin);
          document.location = payload.routeAfterLogin;
        } else if (payload.type === this.$USER) {
          document.location = '/landing';
        } else {
          document.location = '/admin';
        }
      } else {
        console.log('login.js login: Login Silently Works!');
      }
    } catch (err) {
      this.$warning(`Oops! Something happened when logging you in, please try again,
if the problem persist contact the system administrator`);
      return Promise.reject(err);
    }
  },

  async startSession(ctx, user) {
    try {
      const response = await this.$axios.post('/authenticate', user);
      return response;
    } catch (err) {
      let cause = this.$helpers.findDeepProp('response.data.details.cause', err);

      if (cause === 'SelfValidationNeeded') {
        document.location = this.$router.
          resolve({ name: 'amount', query: { userId: err.response.data.userId }}).href;
        return false;
      }

      if (cause === 'EmailNotValid') {
        document.location = this.$router.
          resolve({
            name: 'send-email-confirmation',
            query: { email: user.email },
          }).href;
        return false;
      }

      if (cause === 'PendingApproval') {
        this.$warning(`Your account is not active, please contact any of the fully active
account holders for your vendor to verify and activate your account`);
        return false;
      }

      // this.$warning('Please check your username and password');

      this.$modal.show(LoginError, {
        clickToClose: true,
      });

      // this.$modal.show('dialog', {
      //   title: 'Unsuccessful login attempt.',
      //   text: `Welcome to the new, more secure Vendor Payments site.
      //     If you already had an account on the old website, please note that you will
      //      need to register again here to verify your identity.`,
      // });

      // this.$defaultResponseController(err);
      return false;
    }
  },

  /**
   * Execute Login Silently
   */
  runLoginSilently({ state, commit, dispatch }) {
    console.log("login.js runLoginSilently is running");
    clearTimeout(state.silentlyLoginTimeout);

    return new Promise((resolve, reject) => {
      this.$axios.post('/refresh-token', { refreshToken: state.loggedIn.token })
        .then((response) => {
          const newAuth = response.data;
          newAuth.doNotRedirect = true;

          // commit('updateAuth', newAuth);

          dispatch('login', newAuth)
            .then(() => {
              resolve('It looks like everything goes good!');
            });
        })
        .catch((err) => {
          if (process.env.debug) {
            console.log("Ops! something when wrong doing the silently login... trying again.");
            console.warn(err);
          }

          if (state.killSilentlyAfterTimes > 0) {
            dispatch('silentlyLoginTimeout');
            commit('updateKillSilentlyAfterTimes');
          }
          reject(err);
        });
    });
  },

  /**
   * Set Silently Login Timeout
   */
  silentlyLoginTimeout({ state, commit, dispatch }) {
    if (typeof state.loggedIn.expirationTime !== 'undefined') {
      try {
        console.log("FUNCTION silentlyLoginTimeout is running, state.loggedIn.expirationTime", state.loggedIn.expirationTime, 'state.silentlyLoginTimeout:', state.silentlyLoginTimeout);
        const expire_time = this.$dayjs(state.loggedIn.expirationTime);
        // console.log("expire_time", expire_time);
        const diff = expire_time.diff(this.$dayjs(new Date()), 'seconds');
        console.warn(`AUTOLOGIN IN: ${diff}`);
        if (diff > state.secondsBeforeSilentlyLogin) {
          if (state.silentlyLoginTimeout) {
            clearTimeout(state.silentlyLoginTimeout);
          }
          const tempTimeout = setTimeout(() => {
            console.log('FUNCTION silentlyLoginTimeout still running inside timeout section, calling runLoginSilently');
            dispatch('runLoginSilently');
          }, ((diff - state.secondsBeforeSilentlyLogin) * 1000));
          console.log('FUNCTION silentlyLoginTimeout is still running after timeout section, about to set PROPERTY silentlyLoginTimeout to tempTimeout:', tempTimeout);
          commit('setSilentlyLoginTimeout', tempTimeout);
        } else {
          dispatch('runLoginSilently');
        }
      } catch (err) {
        console.warn(err);
      }
    }
    console.log('FUNCTION silentlyLoginTimeout is ending');
  },


  async logOut() {
    console.log('store/login.js logOut is running');
    // debugger;
    try {
      await this.$axios.post('/logout', {});
    } catch (err) {
      // Silence is golden!
    }

    // Log the user out no matter what.
    this.$sCache.clear('loggedIn');
    document.location = '/login';
  },

  async sentResetPasswordEmail(ctx, emailAddress) {
    try {
      const ops = { headers: { "Content-Type": "application/json" }};
      await this.$axios.put('/Users/SendResetPasswordEmail', JSON.stringify(emailAddress), ops);
      return true;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },

  async emailConfirmation(ctx, token) {
    try {
      const ops = { headers: { "Authorization": `Bearer ${token}` }};
      const { data } = await this.$axios.put('/Users/EmailConfirmation', {}, ops);
      return data;
    } catch (err) {
      console.log(err);
      this.$defaultResponseController(err);
      return false;
    }
  },

  async sendConfirmationEmail(ctx, email) {
    try {
      await this.$axios.post('/Users/SendConfirmationEmail', {},
        { params: { emailaddress: email }});
      return true;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },

  async validateToken(ctx, token) {
    try {
      await this.$axios.put('/Users/TokenValidation', {}, { params: { tokenStr: token }});
      return true;
    } catch (err) {
      this.$sCache.set('flash-message',
        { type: 'warning', message: `The reset password token has expired,
          please follow the "I forgot my password" process again.` }
      );
      document.location = '/login';
      return false;
    }
  },

  async resetPassword(ctx, payload) {
    try {
      const ops = { headers: { "Authorization": `Bearer ${payload.token}` }};
      await this.$axios.put('/Users/ResetPassword', { password: payload.password }, ops);
      return true;
    } catch (err) {
      this.$defaultResponseController(err);
      return false;
    }
  },

};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
