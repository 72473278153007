import "./assets/scss/main.scss";
import 'animate.css/animate.min.css';
import "@fortawesome/fontawesome-pro/css/all.css";


// A bit dirty but needed polyfill
Number.isNaN = Number.isNaN || function isNaN(input) {
  return typeof input === 'number' && input !== input;
};

import Vue from 'vue';
import * as PhilaUI from '@phila/phila-ui';
import App from './App.vue';
import router from './router';
import helpers from '@/plugins/helpers';
import dayjs from './config/dayjs';
import store from './store/store';
import axios from './config/axios';
import constants from './config/constants';
import notifications from './config/notifications';
import sCache from '@/plugins/simple-cache';
import IdleVue from 'idle-vue';
import VModal from 'vue-js-modal';
import { ClientTable } from 'vue-tables-2';
import { EventBus } from './plugins/bus';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
  config:{
    id: 'UA-860026-1',
    appName: 'Vendor Payments',
  },
}, router);

library.add(faInfoCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(PhilaUI, {});

require('./config/layouts');
require('./config/components');
// require('./config/validation');

Vue.config.productionTip = false;

store.$router = router;

Vue.use(constants, { store });
Vue.use(dayjs, { store });
Vue.use(notifications, { store });
Vue.use(axios, { store });

Vue.use(VModal, {
  dynamic: true,
  dialog: true,
  dynamicDefaults: { clickToClose: false },
});

// Set modal in store
store.$modal = Vue.prototype.$modal;

const eventsHub = new Vue();
Vue.use(IdleVue, { idleTime: (7 * 60 * 1000), eventEmitter: eventsHub, store });

Vue.use(ClientTable, {
  skin: "VueTables__table is-align-middle table is-bordered is-striped is-hoverable is-fullwidth fixed",
}, false, 'bulma');

Vue.use(helpers, { store });

/** Vue Mixins. */
Vue.mixin({
  computed: {
    isLoggedIn() {
      return this.$store.getters['login/isLoggedIn'];
    },
    isAdmin() {
      return this.$store.getters['login/isAdmin'];
    },
    loggedIn() {
      return this.$store.state.login.loggedIn;
    },
    curUserType() {
      if (this.isLoggedIn) {
        let value = this.$store.state.login.loggedIn.type;
        if (Array.isArray(value)) {
          if (value.includes('AchAdministrator')) {
            value = 'AchAdministrator';
          }
        }
        console.log('in main.js, setting curUserType, value:', value);
        return value;
      }
      return null;
    },
    hideEdges() {
      if (this.tableValues && this.tableValues.length > 0) {
        return (Math.ceil(this.tableValues.length / this.tableOptions.perPage) <
        this.tableOptions.pagination.chunk);
      }
      return false;
    },
  },
  methods: {
    onlyFor(type) {
      return this.curUserType === type;
    },
  },
});

// Before doing anything, let's do a health check.
store.dispatch('healthCheck');

const VueCaller = () => {
  let app = new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');

  EventBus.$on('offline', () => {
    app.$modal.show('dialog', {
      title: 'Offline?',
      text: `It looks like you are offline, please check your internet connection.`,
    });
  });

  EventBus.$on('online', () => {
    app.$modal.show('hide');
  });

  // Online/Offline Event Listener
  window.addEventListener('online', () => {
    EventBus.$emit('online');
  });

  window.addEventListener('offline', () => {
    EventBus.$emit('offline');
  });
};

const ctx = {
  store,
  params: router.history.current.params,
  query: router.history.current.query,
  redirect: router.push,
  sCache,
};

if(typeof App.fetch === 'function') {
  (async () => {
    await App.fetch.apply(null, [ ctx ]);
    VueCaller();
  })();
} else {
  VueCaller();
}
