<template>
  <div class="modal is-medium is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Important information!
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close');"
        />
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p>You have been redirected to the new City Of Philadelphia's new Vendor Payments site. In the near future, the old application will be decommissioned and this will replace it. The upgraded vendor payment site has improved web accessibility, and new search by invoice number functionality.</p>
          <p>
            <strong>As a security measure, you are required to create a new account</strong> on the
            <a :href="$router.resolve({ name: 'register' }).href">
              registration page.
            </a>
            Instructions on how to register can be found in the 
            <router-link  
              target="_blank"
              :to="{ name: 'faq' }"
            >
              FAQ page
            </router-link>
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewAppNotification',
};
</script>

<style>

</style>